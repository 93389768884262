<style lang="scss">
.topplatzierung-politik {
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
  .image-box {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.outer-slider {
  .inner-box {
    padding-top: 0px !important;
    .kat {
      display: none !important;
    }
  }
}
</style>

<template>
  <div class="topplatzierung-politik content container">
    <h1>Top-Platzierung Politik</h1>
    <div class="row">
      <div class="col-lg-8 col-md-10">
        <p class="intro-txt">
          Top bedeutet ganz vorne dabei. Profitieren Sie davon, dass Ihre Werbeeinschaltung im Politik-Teil der Kleinen Zeitung und im anzeigenfreien Raum davor und danach als besonders glaubwürdig und seriös wahrgenommen wird.
        </p>
      </div>
    </div>
  </div>
  <div class="bg-blue-light margin-b-m">
    <div class="wrapper-xxl outer-slider">
      <!-- -->
      <div class="print-overview-slider">
        <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
        <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay>
          <template #navigation="{ left, right }">
            <div class="slider-navi-outer">
              <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
              <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
            </div>
          </template>

          <template #items>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/2_Politik-1-von-2_Top.jpg" alt="Top-Platzierung Politik" class="img-fluid" />
                </div>
                <div class="kat">Top-Platzierung Politik</div>
                <router-link :to="{ name: 'TopplatzierungPolitik' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/2_Politik-2-von-2_Top.jpg" alt="Top-Platzierung Politik" class="img-fluid" />
                </div>
                <div class="kat">Top-Platzierung Politik</div>
                <router-link :to="{ name: 'TopplatzierungPolitik' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/print/platzierungen/5_International-2-von-2_Themen.jpg" alt="Top-Platzierung Politik" class="img-fluid" />
                </div>
                <div class="kat">Top-Platzierung Politik</div>
                <router-link :to="{ name: 'Themenplatzierung' }" class="stretched-link"></router-link>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
      <!-- -->
    </div>
  </div>

  <div class="topplatzierung-politik content container">
    <div class="text-center">
      <CollapsibleButton link="topplatzierungen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
    </div>
    <Collapsible link="topplatzierungen-tabellen">
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Millimeterpreis 4C</strong>
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>

            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">37,06</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">26,57</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">16,28</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">39,05</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">27,83</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">17,05</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3"></div>
      </div>

      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/8 Seite</strong>
              Fixformat: 47 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.003,10</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.586,95</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.197,80</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">5.271,75</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">3.757,05</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">2.301,75</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/4 Seite</strong>
              Fixformat: 98 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">10.006,20</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7.173,90</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.395,60</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">10.543,50</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">7.514,10</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">4.603,50</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/3 Seite</strong>
              Fixformat: 200 x 90 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">13.341,60</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">9.565,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">5.860,80</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">14.058,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">10.018,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">6.138,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90.svg" alt="200x90" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/2 Seite</strong>
              Fixformat: 200 x 135 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">20.012,40</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">14.347,80</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">8.791,20</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">21.087,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">15.028,20</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9.207,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90.svg" alt="200x90" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>Junior Page</strong>
              Fixformat: 149 x 200 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">22.236,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">15.942,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">9.768,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">23.430,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">16.698,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">10.230,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x200.svg" alt="149x200" class="img-fluid " />
        </div>
      </div>
      <div class="row format-box">
        <div class="col-lg-7 order-lg-2">
          <div class="tabelle-box">
            <div class="headline">
              <strong>1/1 Seite</strong>
              Fixformat: 200 x 275 mm
            </div>
            <div class="row head-row hide-small">
              <div class="col-lg-3"></div>
              <div class="col-lg-3 text-align-right">Kombination</div>
              <div class="col-lg-3 text-align-right">Steiermark</div>
              <div class="col-lg-3 text-align-right">Kärnten</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">MO - FR</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">40.766,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">29.227,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">17.908,00</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-3 text-align-center">SA - SO</div>
              <div class="col-6 text-align-left visible-small">Kombination</div>
              <div class="col-lg-3 col-6 text-align-right">42.955,00</div>
              <div class="col-6 text-align-left visible-small">Steiermark</div>
              <div class="col-lg-3 col-6 text-align-right">30.613,00</div>
              <div class="col-6 text-align-left visible-small">Kärnten</div>
              <div class="col-lg-3 col-6 text-align-right">18.755,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
        </div>
      </div>

      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer. Titel-, Aufschlagseite, U4 inklusive Aufpreis.</div>
    </Collapsible>

    <!--<div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/hauptblatt/Top-Platzierung-Politik.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Topplatzierung Politik
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>70 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    CollapsibleButton,
    Collapsible,
    Slider,
    SliderItem,
  },
});
</script>
